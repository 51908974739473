<template>

  <h1 class="my-5 ">Dit is de Register pagina.</h1>

</template>

<script>




export default {

  props:["event"],
}
</script>
<style scoped></style>